/* == customers == */
.customers-Container {
    padding: 20px 10px;
}
.customers-Container .serviceCard {
    background-color: #fff;
    width: 100%;
    height: auto;
    padding: 44px 26px;
    border-radius: 12px;
    text-align: center;
    margin-bottom: 18px;
    box-shadow: 4px 5px 5px 5px #e3e3e3;
}

.customers-Container .table td, .table th {
    padding: 14px 14px !important;
    text-align: initial;
    font-size: 13px;
}

/* Driver Details  */
.driver-Container {
    padding: 20px 10px;
}
.driver-Container .serviceCard {
    background-color: #fff;
    width: 100%;
    height: auto;
    padding: 44px 26px;
    border-radius: 12px;
    text-align: center;
    margin-bottom: 18px;
    box-shadow: 4px 5px 5px 5px #e3e3e3;
}
.driver-Container .driver-details{
    font-size: 16px;
    font-family: Poppins-SemiBold;
    color: #5c5c5c;
}

/* Add Admin  */
.admin-Container{
    padding: 20px 10px;
}
.admin-Container .serviceCard {
    background-color: #fff;
    width: 100%;
    height: auto;
    padding: 44px 26px;
    border-radius: 12px;
    text-align: center;
    margin-bottom: 18px;
    box-shadow: 4px 5px 5px 5px #e3e3e3;
}

.admin-Container input{
    border-radius: 30px;
    padding: 0px 22px;
}

/* Services */
.add-services:hover{
    text-decoration: underline;
    color: #FF9517;
}
.service-Modal{
    margin-top: 14%;
}

.service-Modal textarea {
    height: 120px;
    border-radius: 8px;
    padding: 10px 25px;
}
.service-Modal input {
    border-radius: 8px;
    padding: 10px 25px;
}
.service-Modal .modal-title{
    font-size: 24px;
    font-family: Poppins-SemiBold;
    color: #212529d1;
}

.service-Modal .close{
    position: absolute;
    right: 1px;
    top: 0px;
    font-size: 18px;
    color: #b31000;
}


/* Modal  */
.modal-content {
    padding: 18px 40px;
    text-align: center;
}

.pagination-container ul{
    display: flex !important;
    justify-content: space-evenly !important;
    align-items: center !important;
}
.pagination-container ul li{
    list-style: none;
}

.loader-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.RemoveBtn:hover{
    color: #be103b;
    box-shadow: 0px 2px 12px #e2c4c4;
}

.text-newWrap{
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}