/* Poppins Font Family */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600&family=Roboto:wght@100;300;400;500&display=swap');
/* Poppins Font Family */

:root {
    --first-color:#FF9517;
    --second-color:#0282A5;
    --third-color:#000;
    --fourth-color:#fff;
}
.blueish {
    color: var(--second-color) !important;
}
h1,h2,h3,h4,h5,h6 {
    font-family: 'Poppins';
}
h2 {
    font-size: 44px;
    font-weight: 500;
}
h3 {
    font-size: 25px;
    font-weight: 400;
}
h5,label {
    font-size: 15px;
    font-weight: 400;
}
a {
    font-size: 15px;
    font-weight: 500;
    text-decoration: none !important;
}
p {
    font-family: 'Poppins';
    font-size: 18px;
    font-weight: 500;
}

/* Signin Css Start Here */
.singnin {
    height: 100vh;
}
.singnin .daja-image {
    height: 100vh;
    background-image: url('../images/back.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.singnin .daja-image .logoDv {
    text-align: center;
    padding-top: 15%;
    margin-left: -10%;
}
.singnin .daja-image .logoDv figure img {
    width: 160px;
}
.singnin .dajaFrom {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.singnin .dajaFrom .logoDv figure img {
    width: 160px;
}
.singnin .dajaFrom h2.title {
    font-weight: 600;
    font-size: 34px;
}
.singnin .dajaFrom .textDv h4.bld {
    font-weight: 500;
}
.singnin .dajaFrom .memberLink p a {
    font-size: 18px;
    margin: 0 10px;
}
.singnin .dajaFrom .form-group .form-control {
    border: 1px solid #0000002b;
    border-radius: 50px;
    padding: 12px 30px;
    color: #636060;
}
.singnin .dajaFrom .form-group .form-control::placeholder {
    opacity: 0.5;
}
.singnin .dajaFrom .form-group button.btn {
    background: var(--first-color);
    color: #fff;
    font-family: 'Poppins';
    padding: 15px 0;
    transition: 0.6s;
    border: 2px solid var(--first-color);
}
.singnin .dajaFrom .form-group button.btn:hover {
    background: transparent;
    color: var(--first-color);
}
.singnin .dajaFrom .optionDv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
}
.singnin .dajaFrom .optionDv .rememberDv {
    width: 130px;
    color: #636060;
}
.singnin .dajaFrom .optionDv .rememberDv label {
    margin-bottom: 0;
}
.singnin .dajaFrom .privacyDv {
    padding-top: 20px;
}
.singnin .dajaFrom .privacyDv h5 {
    color: #636060;
}
/* Signin Css Start Here */

/* Media Query Start Here */
@media (max-width: 1101px) {
    .singnin .dajaFrom .privacyDv {
        padding-top: 20px;
    }   
}
@media (max-width: 992px) {
    .singnin .daja-image {
        display: none;
    }
}
@media (max-width: 575px) {
    .singnin .dajaFrom {
        padding: 0 20px;
    }
    .singnin .dajaFrom .privacyDv h5 {
        font-size: 12px;
    }
    .singnin .dajaFrom .privacyDv h5 a {
        font-size: 12px;
    }
    .singnin .dajaFrom h2.title {
        font-size: 32px;
    }
    .singnin .dajaFrom h4 {
        font-size: 19px;
        line-height: 29px;
    }
}
@media (max-width: 400px) {
    .singnin .dajaFrom .form-group .form-control {
        padding: 10px 20px;
    }
    .singnin .dajaFrom .form-group button.btn {
        padding: 10px 0;
    }
    .singnin .dajaFrom h4 {
        font-size: 14px;
        line-height: 21px;
    }
    .singnin .dajaFrom .memberLink p {
        font-size: 13px;
    }
    .singnin .dajaFrom .memberLink a {
        font-size: 13px;
    }
}
/* Media Query End Here */