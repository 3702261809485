/* == Invoice == */
.Invoice-Container {
    padding: 20px 10px;
}
.Invoice-Container .serviceCard {
    background-color: #fff;
    width: 100%;
    height: auto;
    padding: 44px 26px;
    border-radius: 12px;
    text-align: center;
    margin-bottom: 18px;
    box-shadow: 4px 5px 5px 5px #e3e3e3;
}

.Invoice-Container .tableHead{
    font-size: 14px;
    font-family: Poppins-SemiBold;
    color: #9B9B9B;
}
.Invoice-Container .tableData{
    color: #010101;
    font-size: 13px;
    font-family: Poppins-Regular;
}
.Invoice-Container .table td, .table th {
    padding: 14px 26px !important;
    text-align: initial;
}

/* == Chat == */
.chat-Container {
    padding: 20px 10px;
}
.chat-Container .serviceCard {
    background-color: #fff;
    width: 100%;
    height: 550px;
    padding: 44px 26px;
    border-radius: 12px;
    text-align: center;
    margin-bottom: 18px;
    box-shadow: 4px 5px 5px 5px #e3e3e3;
}

.chat-Container .input-Card{
    background-color: #fff;
    width: 100%;
    height: auto;
    padding: 44px 26px;
    border-radius: 12px;
    text-align: center;
    margin-bottom: 18px;
    box-shadow: 4px 5px 5px 5px #e3e3e3;
}

.chat-Container .chat-header{
    display: flex;
    padding: 0px 50px;
}

.chat-Container .profile{
    height: 60px;
    width: 60px;
    background-color: teal;
    border-radius: 50%;
}
.chat-Container .user-name{
    color: #3A3A3A;
    font-family: Poppins-SemiBold;
    font-size: 16px;
    margin: 0;
    text-align: initial;
    margin-top: 10px;
}
.chat-Container .status{
    font-size: 10px;
    font-family: Poppins-Medium;
    color: #3a3a3a66;
    text-align: initial;
}
.chat-Container .input-Card input {
    /* padding: 10px 40px; */
    padding: 10px 90px 10px 28px;
    width: 100%;
    border-radius: 50px;
    border: 1px solid #e1e1e1;
}
.chat-Container .All-message-container {
    width: 100%;
    height: 370px;
    padding: 28px 20px;
    overflow-y: scroll;
}

.chat-Container .chat-img{
    width: 30px;
    height: 30px;
    background-color: antiquewhite;
    border-radius: 50%;
}

.chat-Container .incoming{
    display: flex;
    margin: 12px;
}
.chat-Container .chat-picture-incoming{
    height: 48px;
    width: 48px;
    background-color: #363535;
    overflow: hidden;
    border-radius: 50%;
}
.chat-Container .chat-message-incoming{
    width: 40%;
    background-color: #FF9517;
    /* margin-top: -10px; */
    overflow: hidden;
    border-radius: 8px;
    margin-left: 14px;
    padding: 12px 16px;
}


.chat-Container .outgoing{
    display: flex;
    flex-direction: row-reverse;
    margin: 12px;
}
.chat-Container .chat-picture-outgoing{
    height: 48px;
    width: 48px;
    background-color: #363535;
    overflow: hidden;
    border-radius: 50%;
}
.chat-Container .chat-message-outgoing {
    width: 40%;
    background-color: #C9C9C9;
    overflow: hidden;
    border-radius: 8px;
    padding: 12px 16px;
    margin-right: 14px;
    /* margin: -10px 14px 16px; */
}
.send-container{
    position: absolute;
    right: 58px;
    bottom: 88px;
}
.send-btn{
    background: #FF9517 !important;
    color: #fff;
    font-size: 12px;
    border-radius: 50px;
    box-shadow: 0 10px 30px 0 rgb(0 0 0 / 15%);
    width: 200px;
    padding: 8px 30px !important;
}

.chat-Container  ::placeholder{
    font-size: 12PX;
}

.test{
    height: 100px;
    width: 100px;
    background-color: teal;
}
.test2{
    display: none;
}

.contractor-form-title {
    font-family: Poppins-SemiBold;
    color: #000000;
    font-size: 20px;
}

/* Profile  */
.profile-Container {
    padding: 20px 10px;
}
.profile-Container  .serviceCard {
    background-color: #fff;
    width: 100%;
    height: auto;
    padding: 44px 26px;
    border-radius: 12px;
    text-align: center;
    margin-bottom: 18px;
    box-shadow: 4px 5px 5px 5px #e3e3e3;
}

.profilePicture-container {
    height: 100px;
    width: 100px;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 10px;
}

.profile-Container .user-title{
    font-size: 16px;
    color: #000000cf;
    font-family: Poppins-SemiBold;
    text-align: initial;
}

.profile-Container .user-details{
    font-size: 16px;
    color: #000000cf;
    font-family: Poppins-SemiBold;
    text-align: initial;
}

.profile-Container  .edit-profile{
    font-size: 12px;
    font-family: Poppins-Regular;
    cursor: pointer;
}
.profile-Container  .edit-profile:hover{
    text-decoration: underline;
}
.profile-Container input{
    border-radius: 30px;
padding: 10px 30px;
}

.profile-Container .edit-container{
    background-color: #f7f7f7;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    position: absolute;
    margin-left: 97px;
    margin-top: 75px;
    box-shadow: 2px 2px 2px #afaeae40;
}
.profile-Container .edit-container input{
    position: absolute;
    margin-left: -18px;
    width: 24px;
    opacity: 0;
}

.edit-pen-icon{
    font-size: 14px !important;
    color: #FF9517 ;
}

/* notification  */
.notification-Container {
    padding: 20px 10px;
}
.notification-Container  .serviceCard {
    background-color: #fff;
    width: 100%;
    height: auto;
    padding: 44px 26px;
    border-radius: 12px;
    text-align: center;
    margin-bottom: 18px;
    box-shadow: 4px 5px 5px 5px #e3e3e3;
}

.notification-Container  .notificationCard {
    background-color: #0282a587;;
    width: 100%;
    height: 80px;
    padding: 18px 26px;
    border-radius: 12px;
    text-align: center;
    margin-bottom: 18px;
    box-shadow: 4px 5px 5px 5px #e3e3e3;
}

.notification-Container .notification-profile{
    height: 46px;
    width: 46px;
    border-radius: 8px;
    overflow: hidden;
}
.notification-Container  .notification-title{
    font-size: 14px;
    margin: 0px;
    font-family: 'Poppins-SemiBold';
    color: white;
}
.notification-Container  .date{
    font-size: 12px;
    font-family: Poppins-Regular;
    color: white;
}


@media (min-width: 768px) and (max-width: 1024px) {
    .Invoice-Container .table td, .table th {
        padding: 14px 20px !important;
    }
    .Invoice-Container .tableHead {
        font-size: 12px;
    }
}
@media (max-width: 768px) {
    .Invoice-Container .table td, .table th {
        padding: 14px 8px !important;
    }
    .chat-Container .serviceCard {
        margin-top: 45px;
        padding: 30px 22px;
    }
    .chat-Container .profile {
        height: 50px;
        width: 50px;
    }
    .chat-Container .All-message-container {
        padding: 16px 16px;
    }
}
@media (max-width: 425px) {
    .Invoice-Container .serviceCard {
        padding: 44px 12px;
        overflow: scroll;
    }
    .chat-Container .serviceCard {
        margin-top: 45px;
        padding: 20px 12px;
    }
    .chat-Container .All-message-container {
        padding: 8px 2px;
    }
    .chat-Container .chat-picture-incoming {
        height: 28px;
        width: 28px;
    }
    .chat-Container .chat-message-incoming {
        width: 50%;
    }
    .chat-Container .chat-message-outgoing {
        width: 50%;
    }
    .chat-Container .chat-picture-outgoing {
        height: 28px;
        width: 28px;
    }
    .chat-Container .input-Card {
        padding: 32px 12px;
    }
    .profile-Container .user-title {
        text-align: center;
    }
    .profile-Container .user-details {
        text-align: center;
    }
    .notification-Container .notificationCard {
        background-color: #0282a587;
        width: 100%;
        height: auto;
        padding: 18px 26px;
        border-radius: 12px;
        text-align: center;
        margin-bottom: 18px;
        box-shadow: 4px 5px 5px 5px #e3e3e3;
    }
    .send-container{
        right: 44px;
        bottom: 76px;
    }
}

@media (max-width: 375px) { 
    .contractor-form-title {
        font-size: 18px;
    }
    .notification-Container .notification-title {
        text-align: initial;
    }
    .notification-Container .date {
        text-align: initial;
    }
    .chat-Container .user-name {
        font-size: 14px;
    }
}

@media (max-width: 375px) { 
    .chat-Container .chat-header {
        display: flex;
        padding: 0px 20px;
    }
}