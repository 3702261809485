@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600&family=Roboto:wght@100;300;400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600&family=Roboto:wght@100;300;400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600&family=Roboto:wght@100;300;400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600&family=Roboto:wght@100;300;400;500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Poppins-Bold';
  font-style: normal;
  font-weight: bold;
  src: local('Poppins-Bold'), url(/static/media/Poppins-Bold.404e299b.ttf) format('woff');
}

@font-face {
  font-family: 'Poppins-Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins-Medium'), url(/static/media/Poppins-Medium.9e1bb626.ttf) format('woff');
}

@font-face {
  font-family: 'Poppins-Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins-Regular'), url(/static/media/Poppins-Regular.8081832f.ttf) format('woff');
}

@font-face {
  font-family: 'Poppins-SemiBold';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins-SemiBold'), url(/static/media/Poppins-SemiBold.cce5625b.ttf) format('woff');
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html {
    overflow-x: hidden;
}

body {
    font-family: "Poppins", sans-serif !important;
    color: #9fa1a4;
    line-height: 1.5;
}

a {
    color: #777;
    text-decoration: none;
    transition: 0.3s all ease;
    outline: 0 !important;
    border: 0 !important;
}

a:hover {
    color: #000;
}

h1, h2, h3, h4, h5, h6, .font-heading {
    font-family: "Poppins", sans-serif;
}

.container {
    z-index: 2;
    position: relative;
}

.text-black {
    color: #000 !important;
}

.text-primary {
    color: #2d71a1 !important;
}

.border-top {
    border-top: 1px solid #f2f2f2 !important;
}

.border-bottom {
    border-bottom: 1px solid #f2f2f2 !important;
}

figure figcaption {
    margin-top: 0.5rem;
    font-style: italic;
    font-size: 0.8rem;
}

section {
    overflow: hidden;
}

/*.section {*/
/*    padding: 7rem 0;*/
/*}*/

.section-heading {
    font-size: 2rem;
    font-weight: 700;
    /*background: linear-gradient(-45deg, #3db3c5, #274685);*/
    /*-webkit-background-clip: text;*/
    /*-webkit-text-fill-color: transparent;*/
    color: #3A3A3A;
}
.text-white {
    color: #fff;
}
.text-black {
    color: #000000;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: 15px;
    z-index: 996;
    background: #2d71a1;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    transition: all 0.4s;
}
.back-to-top i {
    font-size: 28px;
    color: #fff;
    line-height: 0;
}
.back-to-top:hover {
    background: #3687c1;
    color: #fff;
}
.back-to-top.active {
    visibility: visible;
    opacity: 1;
}

/* Default btn sre-tyling */
.btn {
    border: none;
    padding: 0px 30px !important;
}
.btn.btn-outline-white {
    border: 2px solid #fff;
    background: none;
    color: #fff;
}
.btn.btn-outline-white:hover {
    background: #fff;
    color: #2d71a1;
}
.btn.btn-primary {
    background: #2d71a1;
    background: linear-gradient(-45deg, #1391a5, #274685);
    color: #fff;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.15);
}

/* Feature 1 */
.feature-1 .wrap-icon {
    margin: 0 auto;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    position: relative;
    margin-bottom: 30px;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.2);
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.feature-1 .wrap-icon.icon-1 {
    background: linear-gradient(-45deg, #3b87bd, #2d71a2);
}
.feature-1 .wrap-icon i {
    font-size: 40px;
    line-height: 0;
    color: #fff;
}
.feature-1 h3 {
    font-size: 20px;
}
.feature-1 p {
    color: #b1b1b1;
    width: 55%;
    text-align: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

/* Step */
.step {
    box-shadow: 0 10px 30px -5px rgba(0, 0, 0, 0.1);
    background: #fff;
    padding: 35px;
}
.step .number {
    background: linear-gradient(-45deg, #1391a5, #274685);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
    letter-spacing: 0.2rem;
    margin-bottom: 10px;
    display: block;
}
.step p:last-child {
    margin-bottom: 0px;
}

/* Review */
.review h3 {
    font-size: 20px;
}
.review p {
    line-height: 1.8;
    font-style: italic;
    color: #333333;
}
.review .stars span {
    color: #FF8803;
}
.review .stars .muted {
    color: #ccc;
}
.review .review-user img {
    width: 70px;
    margin: 0 auto;
}

/* Testimonial Carousel */
.testimonials-slider .swiper-pagination {
    margin-top: 20px;
    position: relative;
}
.testimonials-slider .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #fff;
    opacity: 1;
    border: 1px solid #2d71a1;
}
.testimonials-slider .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #2d71a1;
}

/* Pricing */
.pricing {
    padding: 50px;
    background: linear-gradient(-45deg, #1391a5, #274685);
    color: #fff;
    padding-bottom: 12rem !important;
    position: relative;
    border-radius: 4px;
    color: #000000;
    background: #fff;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.2);
}
.pricing h3 {
    color: #2d71a1;
}
.pricing .price-cta {
    text-decoration: none;
    position: absolute;
    bottom: 50px;
    width: 100%;
    text-align: center;
    left: 0;
}
.pricing .price-cta .price {
    display: block;
    margin-bottom: 20px;
    font-size: 2rem;
    font-weight: 300;
}
.pricing .popularity {
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 0.2rem;
    display: block;
    margin-bottom: 20px;
}
.pricing ul {
    margin-bottom: 50px;
}
.pricing ul li {
    margin-bottom: 10px;
}
.pricing .btn-white {
    background: #fff;
    border: 2px solid #f1f1f1;
    border-radius: 4px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
}
.pricing .btn-white:hover {
    color: #2d71a1;
}
.pricing.popular {
    background: #fff;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.2);
    color: #000000;
    background: linear-gradient(-45deg, #1391a5, #274685);
    color: #fff;
    box-shadow: none;
}
.pricing.popular .popularity {
    color: #b3b3b3;
}
.pricing.popular h3 {
    color: #fff;
    background: none;
}
.pricing.popular .btn-white {
    border: 2px solid #2d71a1;
}

/* CTA Section */
.cta-section {
    background: linear-gradient(to right, #274685 0%, #3db3c5 100%);
    color: #fff;
}
.cta-section h2 {
    color: #fff;
    font-size: 3rem;
    font-weight: 700;
}
@media screen and (max-width: 768px) {
    .cta-section h2 {
        font-size: 2rem;
    }
    .package-input{
        width: auto !important;
    }
    .about-section{
        height: 400px !important;
    }
    .why-daja-section {
        height: 1100px;
    }
}
.cta-section .btn {
    background: #000000;
    color: #fff;
}
.cta-section .btn i {
    margin-right: 5px;
    font-size: 24px;
    line-height: 0;
}

/* Contact Form */
.form-control {
    height: 40px;
    border-radius: 0;
    border: 1px solid #dae0e5;
}
.form-control:active, .form-control:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid #2d71a1;
}

.php-email-form .validate {
    display: none;
    color: red;
    margin: 0 0 15px 0;
    font-weight: 400;
    font-size: 13px;
}
.php-email-form .error-message {
    display: none;
    color: #fff;
    background: #ed3c0d;
    text-align: left;
    padding: 15px;
    font-weight: 600;
}
.php-email-form .error-message br + br {
    margin-top: 25px;
}
.php-email-form .sent-message {
    display: none;
    color: #fff;
    background: #18d26e;
    text-align: center;
    padding: 15px;
    font-weight: 600;
}
.php-email-form .loading {
    display: none;
    background: #fff;
    text-align: center;
    padding: 15px;
}
.php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #18d26e;
    border-top-color: #eee;
    animation: animate-loading 1s linear infinite;
}
.php-email-form textarea {
    min-height: 160px;
}

@keyframes animate-loading {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
/* Blog */
.post-entry {
    margin-bottom: 60px;
}
.post-entry .post-text .post-meta {
    text-decoration: none;
    color: #ccc;
    font-size: 13px;
    display: block;
    margin-bottom: 10px;
}
.post-entry .post-text h3 {
    font-size: 20px;
    color: #000000;
}
.post-entry .post-text h3 a {
    text-decoration: none;
    color: #000000;
}
.post-entry .post-text h3 a:hover {
    text-decoration: none;
    color: #2d71a1;
}
.post-entry .post-text .readmore {
    color: #2d71a1;
}

.sidebar-box {
    margin-bottom: 30px;
    padding: 25px;
    font-size: 15px;
    width: 100%;
    float: left;
    background: #fff;
}
.sidebar-box *:last-child {
    margin-bottom: 0;
}
.sidebar-box h3 {
    font-size: 18px;
    margin-bottom: 15px;
}

.categories li, .sidelink li {
    position: relative;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px dotted gray("300");
    list-style: none;
}
.categories li:last-child, .sidelink li:last-child {
    margin-bottom: 0;
    border-bottom: none;
    padding-bottom: 0;
}
.categories li a, .sidelink li a {
    text-decoration: none;
    display: block;
}
.categories li a span, .sidelink li a span {
    position: absolute;
    right: 0;
    top: 0;
    color: #ccc;
}
.categories li.active a, .sidelink li.active a {
    text-decoration: none;
    color: #000000;
    font-style: italic;
}

.comment-form-wrap {
    clear: both;
}

.comment-list {
    padding: 0;
    margin: 0;
}
.comment-list .children {
    padding: 50px 0 0 40px;
    margin: 0;
    float: left;
    width: 100%;
}
.comment-list li {
    padding: 0;
    margin: 0 0 30px 0;
    float: left;
    width: 100%;
    clear: both;
    list-style: none;
}
.comment-list li .vcard {
    width: 80px;
    float: left;
}
.comment-list li .vcard img {
    width: 50px;
    border-radius: 50%;
}
.comment-list li .comment-body {
    float: right;
    width: calc(100% - 80px);
}
.comment-list li .comment-body h3 {
    font-size: 20px;
}
.comment-list li .comment-body .meta {
    text-decoration: none;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 0.1em;
    color: #ccc;
}
.comment-list li .comment-body .reply {
    padding: 5px 10px;
    background: #e6e6e6;
    color: #000000;
    text-transform: uppercase;
    font-size: 14px;
}
.comment-list li .comment-body .reply:hover {
    color: #000000;
    background: #e3e3e3;
}

.search-form {
    background: #f7f7f7;
    padding: 10px;
}
.search-form .form-group {
    position: relative;
}
.search-form .form-group input {
    padding-right: 50px;
}
.search-form .icon {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
    height: 80px;
    transition: all 0.5s;
    z-index: 997;
    transition: all 0.5s;
}
#header.header-scrolled {
    background: rgb(57 57 57);
    height: 60px;
}
#header .logo h1 {
    font-size: 28px;
    margin: 0;
    padding: 4px 0;
    line-height: 1;
    font-weight: 500;
}
#header .logo h1 a, #header .logo h1 a:hover {
    color: #fff;
    text-decoration: none;
}
#header .logo img {
    padding: 0;
    margin: 0;
    max-height: 40px;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation
*/
.navbar {
    /* padding: 0; */
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
}
.navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
}
.navbar li {
    position: relative;
}
#header .navbar a, .navbar a {
  
    padding: 10px 0 10px 30px;
    /* padding: 10px 0 10px 0px; */
  
}
.navbar a, .navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding: 10px 0 10px 30px; */
    /* padding: 10px 0 10px 0px; */
    font-size: 13px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.65);
    white-space: nowrap;
    transition: 0.3s;
}
#header .navbar a, .navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding: 10px 0 10px 30px; */
    /* padding: 10px 0 10px 0px; */
    font-size: 13px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.65);
    white-space: nowrap;
    transition: 0.3s;
}
.navbar a i, .navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
}
.navbar a:hover, .navbar .active, .navbar .active:focus, .navbar li:hover > a {
    color: #fff;
}
.navbar .dropdown ul {
    display: block;
    position: absolute;
    left: 14px;
    top: calc(100% + 30px);
    margin: 0;
    padding: 10px 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: 0.3s;
    border-radius: 4px;
}
.navbar .dropdown ul li {
    min-width: 200px;
}
.navbar .dropdown ul a {
    padding: 10px 20px;
    font-size: 15px;
    color: #101c36;
}
.navbar .dropdown ul a i {
    font-size: 12px;
}
.navbar .dropdown ul a:hover, .navbar .dropdown ul .active:hover, .navbar .dropdown ul li:hover > a {
    color: #2d71a1;
}
.navbar .dropdown:hover > ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
}
.navbar .dropdown .dropdown ul {
    top: 0;
    left: calc(100% - 30px);
    visibility: hidden;
}
.navbar .dropdown .dropdown:hover > ul {
    opacity: 1;
    top: 0;
    left: 100%;
    visibility: visible;
}
@media (max-width: 1366px) {
    .navbar .dropdown .dropdown ul {
        left: -90%;
    }
    .navbar .dropdown .dropdown:hover > ul {
        left: -100%;
    }
}

/**
* Mobile Navigation
*/
.mobile-nav-toggle {
    color: #fff;
    font-size: 28px;
    cursor: pointer;
    display: none;
    line-height: 0;
    transition: 0.5s;
}

@media (max-width: 991px) {
    .mobile-nav-toggle {
        display: block;
    }

    #header .navbar ul {
        display: none;
    }
}
.navbar-mobile {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(27, 49, 94, 0.9);
    transition: 0.3s;
    z-index: 999;
}
.navbar-mobile .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
}
.navbar-mobile ul {
    display: block;
    position: absolute;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 10px 0;
    border-radius: 10px;
    background-color: #fff;
    overflow-y: auto;
    transition: 0.3s;
}
.navbar-mobile a, .navbar-mobile a:focus {
    padding: 10px 20px;
    font-size: 15px;
    color: #101c36;
}
.navbar-mobile a:hover, .navbar-mobile .active, .navbar-mobile li:hover > a {
    color: #2d71a1;
}
.navbar-mobile .dropdown ul {
    position: static;
    display: none;
    margin: 10px 20px;
    padding: 10px 0;
    z-index: 99;
    opacity: 1;
    visibility: visible;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}
.navbar-mobile .dropdown ul li {
    min-width: 200px;
}
.navbar-mobile .dropdown ul a {
    padding: 10px 20px;
}
.navbar-mobile .dropdown ul a i {
    font-size: 12px;
}
.navbar-mobile .dropdown ul a:hover, .navbar-mobile .dropdown ul .active:hover, .navbar-mobile .dropdown ul li:hover > a {
    color: #2d71a1;
}
.navbar-mobile .dropdown > .dropdown-active {
    display: block;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.hero-section {
    background: linear-gradient(to right, rgba(39, 70, 133, 0.0) 0%, rgba(61, 179, 197, 0.0) 100%), url(/static/media/main-banner.e5ccd002.png);
    background-size: cover;
    position: relative;
}
.hero-section .wave {
    width: 100%;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    bottom: -150px;
}
@media screen and (max-width: 992px) {
    .hero-section .wave {
        bottom: -180px;
    }
}
.hero-section .wave svg {
    width: 100%;
}
.hero-section, .hero-section > .container > .row {
    height: 100vh;
    min-height: 950px;
}
.hero-section.inner-page {
    height: 60vh;
    min-height: 0;
}
.hero-section.inner-page .hero-text {
    transform: translateY(-150px);
    margin-top: -120px;
}
@media screen and (max-width: 992px) {
    .hero-section.inner-page .hero-text {
        margin-top: -80px;
    }
}
.hero-section h1 {
    font-size: 3.5rem;
    color: #fff;
    font-weight: 700;
    margin-bottom: 30px;
}
@media screen and (max-width: 992px) {
    .hero-section h1 {
        font-size: 2.5rem;
        text-align: center;
        margin-top: 40px;
    }
}
@media screen and (max-width: 992px) {
    .hero-section .hero-text-image {
        margin-top: 4rem;
    }
}
.hero-section p {
    /*font-size: 18px;*/
    color: #fff;
    text-align: left;
}
.hero-section .iphone-wrap {
    position: relative;
}
@media screen and (max-width: 992px) {
    .hero-section .iphone-wrap {
        text-align: center;
    }
}
.hero-section .iphone-wrap .phone-2, .hero-section .iphone-wrap .phone-1 {
    position: absolute;
    top: -50%;
    overflow: hidden;
    left: 0;
    box-shadow: 0 15px 50px 0 rgba(0, 0, 0, 0.3);
    border-radius: 30px;
}
@media screen and (max-width: 992px) {
    .hero-section .iphone-wrap .phone-2, .hero-section .iphone-wrap .phone-1 {
        position: relative;
        top: 0;
        max-width: 100%;
    }
}
.hero-section .iphone-wrap .phone-2, .hero-section .iphone-wrap .phone-1 {
    width: 250px;
}
@media screen and (max-width: 992px) {
    .hero-section .iphone-wrap .phone-1 {
        margin-left: -150px;
    }
}
.hero-section .iphone-wrap .phone-2 {
    margin-top: 50px;
    margin-left: 100px;
    width: 250px;
}
@media screen and (max-width: 992px) {
    .hero-section .iphone-wrap .phone-2 {
        width: 250px;
        position: absolute;
        margin-top: 0px;
        margin-left: 100px;
    }
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.footer {
    padding: 5rem 0 2.5rem 0;
}
.footer h3 {
    font-size: 18px;
    margin-bottom: 30px;
}
.footer ul li {
    margin-bottom: 10px;
}
.footer a {
    color: #000;
}
.footer .copyright {
    margin-bottom: 0px;
}
.footer .copyright, .footer .credits {
    font-size: 14px;
}

.social a {
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    /*background: #f8f9fa;*/
    position: relative;
    text-align: center;
    transition: 0.3s background ease;
    color: #0d1e2d;
    line-height: 0;
}
.social a span {
    display: inline-block;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}
.social a:hover {
    color: #fff;
    background: transparent;
}
.social a i {
    line-height: 0;
}
/*Custom CSS*/
.login-btn {
    background: #FF9517 !important;
    text-align: center;
    padding: 10px 30px !important;
    vertical-align: middle;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    line-height: 1.5;
    border-radius: 50px;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.top-bar {
    background: #ff9517;
    margin-top: 75px;
}
.top-bar p {
    margin-top: 20px;
    margin-left: 50px;
}
.top-bar {
    display: flex;                  /* establish flex container */
    flex-direction: row;            /* default value; can be omitted */
    flex-wrap: nowrap;              /* default value; can be omitted */
    justify-content: center; /* switched from default (flex-start, see below) */
}
/*.slider-text {*/
/*    margin-top: -40vh;*/
/*}*/
/*.play_btn {*/
/*    margin-top: -37px;*/
/*}*/
.slider-row {
    margin-top: -28vh;
}
.track-package {
    margin-top: 20vh;
}
.package-text {
    letter-spacing: 1.1px;
}
.package-input {
    border-radius: 50px 0px 0px 50px;
    padding: 9px 30px !important;
    width: 30%;
    outline: 0;
    border: 0px;
    font-size: 13px;
}
.package-btn {
    background: #FF9517;
    color: #fff;
    text-align: center;
    padding: 6px 30px !important;
    vertical-align: middle;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    line-height: 1.5;
    border-radius: 0px 50px 50px 0px;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    margin-left: -30px;
}
.apply-now-btn {
    margin-top: 15px;
    display: block;
    margin-left: 45px;
    border: 1px solid #fff !important;
    text-align: center;
    padding: 5px 10px !important;
    vertical-align: middle;
    -ms-user-select: none;
    -webkit-user-select: none;
            user-select: none;
    line-height: 1.5;
    border-radius: 50px;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    color: #fff;
    font-size: 13px;
}
.top-bar img {
    width: 50px;
    margin-top: 5px;
}
.card-1 {
    background: #48A9E1;
    padding: 35px;
    border-radius: 50px;
}
.deliver-card {
    border-radius: 50px;
}
.about-section {
    background: url(/static/media/gray-bg.2da336f7.png);
    background-size: cover;
    position: relative;
    /*min-height: 600px;*/
    height: 565px;
}
.about-content {
    margin-top: 14%;
}
/*.black-bg-section {*/
/*    background: url(../img/black-bg.png);*/
/*    background-size: cover;*/
/*    position: relative;*/
/*}*/
.why-daja-section {
    /* background: linear-gradient(to right, rgba(39, 70, 133, 0.0) 0%, rgba(61, 179, 197, 0.0) 100%), url(../img/why-daja1.png) no-repeat; */
    background: url(/static/media/why-daja1.b4bbd0a5.png) no-repeat;
    background-size: cover;
    position: relative;
    /* z-index: 999999; */
    height: 725px;
}
.why-daja-heading {
    margin-top: 25%;
}
/*Footer*/
.footer-16371 {
    padding: 12rem 7rem 5rem 7rem;
    background: url(/static/media/footer-bg1.62b42bda.png) no-repeat;
    background-size: cover;
}
.footer-16371 .footer-site-logo {
    text-align: center;
    font-size: 2rem;
    font-weight: 900;
}
.footer-16371 .footer-site-logo a {
    color: #4200ff;
}
.footer-16371 .nav-links {
    padding: 0;
    margin: 0;
}
.footer-16371 .nav-links li {
    display: inline-block;
}
.footer-16371 .nav-links li a {
    padding: 10px;
    color: #fff;
}
.footer-16371 .nav-links li a:hover {
    color: #ff9517;
}
.footer-16371 .social h3 {
    font-weight: bold;
    font-size: 18px;
    color: #000;
}
.footer-16371 .social ul li {
    display: inline-block;
    padding-right: 30px;
}
.footer-16371 .social ul li a {
    display: inline-block;
    padding: 10px;
}
.footer-16371 .social ul li.in a {
    color: #a7a7a7;
}
.footer-16371 .social ul li.tw a {
    color: #a7a7a7;
    background: transparent;
}
.footer-16371 .social ul li.fb a {
    color: #a7a7a7;
}
.footer-16371 .social ul li.dr a {
    color: #a7a7a7;
}
.footer-16371 .social ul li.pin a {
    color: #a7a7a7;
}
.footer-16371 .copyright {
    color: #999999;
}
.footer-line {
    border-top: 1px solid #707070;
}
.footer-sub-menu li {
    list-style: none;
    float: right;
    padding-left: 15px;
    font-size: 12px;
}
.footer-sub-menu a {
    color: #aebaae;
    font-size: 12px;
}
.sub-menu p {
    font-size: 12px;
    color: #aebaae;
}
.footer-address {
    color: #fff;
}
/*.fb-icon {*/
/*    background: #fff;*/
/*}*/
/* Signin Css Start Here */
.singnin {
    height: 100vh;
}
.singnin .daja-image {
    height: 100vh;
    background: url(/static/media/login-back.a0d2247f.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.singnin .daja-image .logoDv {
    text-align: center;
    padding-top: 15%;
    margin-left: -10%;
}
.singnin .daja-image .logoDv figure img {
    width: 160px;
}
.singnin .dajaFrom {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.singnin .dajaFrom .logoDv figure img {
    width: 160px;
}
.singnin .dajaFrom h2.title {
    font-weight: 600;
}
.singnin .dajaFrom .textDv h4.bld {
    font-weight: 500;
}
.singnin .dajaFrom .form-group .form-control {
    border: 1px solid #0000002b;
    border-radius: 50px;
    padding: 12px 30px;
    color: #636060;
}
.singnin .dajaFrom .form-group .form-control:-ms-input-placeholder {
    opacity: 0.5;
}
.singnin .dajaFrom .form-group .form-control::placeholder {
    opacity: 0.5;
}
.singnin .dajaFrom .form-group button.btn {
    background: #ff9517;
    color: #fff;
    padding: 15px 0;
    transition: 0.6s;
    border: 2px solid #ff9517;
}
.singnin .dajaFrom .form-group button.btn:hover {
    background: transparent;
    color: #ff9517;
}
.singnin .dajaFrom .optionDv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
}
.singnin .dajaFrom .optionDv .rememberDv {
    width: 130px;
    color: #636060;
}
.singnin .dajaFrom .optionDv .rememberDv label {
    margin-bottom: 0;
}
.singnin .dajaFrom .privacyDv {
    padding-top: 60px;
}
.singnin .dajaFrom .privacyDv h5 {
    color: #636060;
}
/* Signin Css Start Here */

/* Media Query Start Here */
@media (max-width: 1101px) {
    .singnin .dajaFrom .privacyDv {
        padding-top: 20px;
    }
}
@media (max-width: 992px) {
    .singnin .daja-image {
        display: none;
    }
}
@media (max-width: 768px) {
    .step {
        padding: 25px 25px;
    }
    
}

@media (min-width: 768px) and (max-width: 1024px) {
    .navbar {
        padding: 0px 10px;
    }
    
}
@media (max-width: 767px) {
    #header .navbar a, .navbar a {
        padding: 10px 0 10px 5px;
    }
    .about-section {
        height: 330px !important;
    }
    
}

@media (max-width: 575px) {
    .singnin .dajaFrom {
        padding: 0 20px;
    }
    .singnin .dajaFrom .privacyDv h5 {
        font-size: 12px;
    }
    .singnin .dajaFrom .privacyDv h5 a {
        font-size: 12px;
    }
    .singnin .dajaFrom h2.title {
        font-size: 32px;
    }
    .singnin .dajaFrom h4 {
        font-size: 19px;
        line-height: 29px;
    }
}
@media (max-width: 425px) {
    .navbar {
        padding: 0px 0px;
    }
    #header .navbar a, .navbar a {
        padding: 10px 0 10px 0px;
    }
    .about-content {
        margin-bottom: 24px;
    }
    .about-section {
        height: auto !important;
    }
    
}
@media (max-width: 400px) {
    .singnin .dajaFrom .form-group .form-control {
        padding: 10px 20px;
    }
    .singnin .dajaFrom .form-group button.btn {
        padding: 10px 0;
    }
    .singnin .dajaFrom h4 {
        font-size: 14px;
        line-height: 21px;
    }
    .singnin .dajaFrom .memberLink p {
        font-size: 13px;
    }
    .singnin .dajaFrom .memberLink a {
        font-size: 13px;
    }
}
/* Media Query End Here */
.description {
    margin: 5% 0 5% 0;
}
.deliver-service-section {
    width: 78%;
    margin-left: 12%;
}
::-webkit-scrollbar {
    width: 0; 
    background: transparent;  
}
::-webkit-scrollbar-thumb {
    background: transparent;
}


/* Poppins Font Family */
/* Poppins Font Family */

:root {
    --first-color:#FF9517;
    --second-color:#0282A5;
    --third-color:#000;
    --fourth-color:#fff;
}
figure {
    margin-bottom: 0;
}
.blueish {
    color: #0282A5 !important;
}
h1,h2,h3,h4,h5,h6 {
    font-family: 'Poppins';
}
h2 {
    font-size: 44px;
    font-weight: 500;
}
h3 {
    font-size: 25px;
    font-weight: 400;
}
h5,label {
    font-size: 15px;
    font-weight: 400;
}
a {
    font-size: 15px;
    font-weight: 500;
    text-decoration: none !important;
}
p {
    font-family: 'Poppins';
    font-size: 18px;
    font-weight: 500;
}

/* Signin Css Start Here */
.singnup {
    height: 100vh;
}
.singnup .daja-image {
    height: 100vh;
    background-image: url(/static/media/back.a0d2247f.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.singnup .daja-image .logoDv {
    text-align: center;
    padding-top: 15%;
    margin-left: -10%;
}
.singnup .daja-image .logoDv figure img {
    width: 160px;
}
.singnup .dajaFrom {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.singnup .dajaFrom .form-Flex {
    width: 100%;
    padding: 0 12%;
}
.singnup .dajaFrom .logoDv figure img {
    width: 160px;
}
.singnup .dajaFrom h2.title {
    font-weight: 600;
    margin-bottom: 0;
    font-size: 34px;
}
.singnup .dajaFrom .textDv h4.bld {
    font-weight: 500;
}
.singnin .dajaFrom .memberLink p a {
    font-size: 18px;
    margin: 0 10px;
}
.singnup .dajaFrom .form-group .form-control {
    border: 1px solid #0000002b;
    border-radius: 50px;
    padding: 0px 30px;
    color: #6360607a;
}
.singnup .dajaFrom .form-group select {
    padding: 0 30px !important;
    height: 43px;
}
.singnup .dajaFrom .form-group .form-control:-ms-input-placeholder {
    opacity: 0.5;
}
.singnup .dajaFrom .form-group .form-control::placeholder {
    opacity: 0.5;
}
.singnup .dajaFrom .form-group button.btn {
    background: #ff9517;
    color: #fff;
    font-family: 'Poppins';
    padding: 9px 0;
    transition: 0.6s;
    border: 2px solid #ff9517;
    width: 60%;
}
.singnup .dajaFrom .form-group button.btn:hover {
    background: transparent;
    color: #ff9517;
}
.authText{
    font-size: 18px;
    color: #000;
    font-family: Poppins-SemiBold;
}

.authText-sub{
    font-size: 22px;
    color: #000;
    font-family: Poppins-Bold;
}
/* Signin Css Start Here */

/* Media Query Start Here */
@media (max-width: 1101px) {
    .singnup .dajaFrom .form-Flex {
        padding: 0 2%;
    }  
}
@media (max-width: 992px) {
    .singnup .dajaFrom .form-Flex {
        padding: 0 15%;
    }
    .singnup .daja-image {
        display: none;
    }
}
@media (max-width: 575px) {
    .singnup .dajaFrom {
        padding: 0 20px;
    }
    .singnup .dajaFrom .form-Flex {
        padding: 3% 10%;
    }
    .singnup .dajaFrom .privacyDv h5 {
        font-size: 12px;
    }
    .singnup .dajaFrom .privacyDv h5 a {
        font-size: 12px;
    }
    .singnup .dajaFrom h2.title {
        font-size: 32px;
    }
    .singnup .dajaFrom h4 {
        font-size: 19px;
        line-height: 29px;
    }
}
@media (max-width: 400px) {
    .singnup .dajaFrom .form-Flex {
        padding: 3% 0%;
    }
    .singnup .dajaFrom .form-group .form-control {
        padding: 10px 20px;
    }
    .singnup .dajaFrom .form-group button.btn {
        padding: 10px 0;
    }
    .singnup .dajaFrom h4 {
        font-size: 14px;
        line-height: 21px;
    }
    .singnup .dajaFrom .memberLink p {
        font-size: 13px;
    }
    .singnup .dajaFrom .memberLink a {
        font-size: 13px;
    }
}
/* Media Query End Here */
/* Poppins Font Family */
/* Poppins Font Family */

:root {
    --first-color:#FF9517;
    --second-color:#0282A5;
    --third-color:#000;
    --fourth-color:#fff;
}
.blueish {
    color: #0282A5 !important;
    color: var(--second-color) !important;
}
h1,h2,h3,h4,h5,h6 {
    font-family: 'Poppins';
}
h2 {
    font-size: 44px;
    font-weight: 500;
}
h3 {
    font-size: 25px;
    font-weight: 400;
}
h5,label {
    font-size: 15px;
    font-weight: 400;
}
a {
    font-size: 15px;
    font-weight: 500;
    text-decoration: none !important;
}
p {
    font-family: 'Poppins';
    font-size: 18px;
    font-weight: 500;
}

/* Signin Css Start Here */
.singnin {
    height: 100vh;
}
.singnin .daja-image {
    height: 100vh;
    background-image: url(/static/media/back.a0d2247f.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.singnin .daja-image .logoDv {
    text-align: center;
    padding-top: 15%;
    margin-left: -10%;
}
.singnin .daja-image .logoDv figure img {
    width: 160px;
}
.singnin .dajaFrom {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.singnin .dajaFrom .logoDv figure img {
    width: 160px;
}
.singnin .dajaFrom h2.title {
    font-weight: 600;
    font-size: 34px;
}
.singnin .dajaFrom .textDv h4.bld {
    font-weight: 500;
}
.singnin .dajaFrom .memberLink p a {
    font-size: 18px;
    margin: 0 10px;
}
.singnin .dajaFrom .form-group .form-control {
    border: 1px solid #0000002b;
    border-radius: 50px;
    padding: 12px 30px;
    color: #636060;
}
.singnin .dajaFrom .form-group .form-control:-ms-input-placeholder {
    opacity: 0.5;
}
.singnin .dajaFrom .form-group .form-control::placeholder {
    opacity: 0.5;
}
.singnin .dajaFrom .form-group button.btn {
    background: #FF9517;
    background: var(--first-color);
    color: #fff;
    font-family: 'Poppins';
    padding: 15px 0;
    transition: 0.6s;
    border: 2px solid #FF9517;
    border: 2px solid var(--first-color);
}
.singnin .dajaFrom .form-group button.btn:hover {
    background: transparent;
    color: #FF9517;
    color: var(--first-color);
}
.singnin .dajaFrom .optionDv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
}
.singnin .dajaFrom .optionDv .rememberDv {
    width: 130px;
    color: #636060;
}
.singnin .dajaFrom .optionDv .rememberDv label {
    margin-bottom: 0;
}
.singnin .dajaFrom .privacyDv {
    padding-top: 20px;
}
.singnin .dajaFrom .privacyDv h5 {
    color: #636060;
}
/* Signin Css Start Here */

/* Media Query Start Here */
@media (max-width: 1101px) {
    .singnin .dajaFrom .privacyDv {
        padding-top: 20px;
    }   
}
@media (max-width: 992px) {
    .singnin .daja-image {
        display: none;
    }
}
@media (max-width: 575px) {
    .singnin .dajaFrom {
        padding: 0 20px;
    }
    .singnin .dajaFrom .privacyDv h5 {
        font-size: 12px;
    }
    .singnin .dajaFrom .privacyDv h5 a {
        font-size: 12px;
    }
    .singnin .dajaFrom h2.title {
        font-size: 32px;
    }
    .singnin .dajaFrom h4 {
        font-size: 19px;
        line-height: 29px;
    }
}
@media (max-width: 400px) {
    .singnin .dajaFrom .form-group .form-control {
        padding: 10px 20px;
    }
    .singnin .dajaFrom .form-group button.btn {
        padding: 10px 0;
    }
    .singnin .dajaFrom h4 {
        font-size: 14px;
        line-height: 21px;
    }
    .singnin .dajaFrom .memberLink p {
        font-size: 13px;
    }
    .singnin .dajaFrom .memberLink a {
        font-size: 13px;
    }
}
/* Media Query End Here */
/* Poppins Font Family */
/* Poppins Font Family */

:root {
    --first-color:#FF9517;
    --second-color:#0282A5;
    --third-color:#000;
    --fourth-color:#fff;
}
.blueish {
    color: #0282A5 !important;
    color: var(--second-color) !important;
}
h1,h2,h3,h4,h5,h6 {
    font-family: 'Poppins';
}
h2 {
    font-size: 44px;
    font-weight: 500;
}
h3 {
    font-size: 25px;
    font-weight: 400;
}
h5,label {
    font-size: 15px;
    font-weight: 400;
}
a {
    font-size: 15px;
    font-weight: 500;
    text-decoration: none !important;
}
p {
    font-family: 'Poppins';
    font-size: 18px;
    font-weight: 500;
}

/* Signin Css Start Here */
.forget {
    height: 100vh;
    overflow: hidden;
}
.forget .daja-image {
    height: 100vh;
    background-image: url(/static/media/back.a0d2247f.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.forget .daja-image .logoDv {
    text-align: center;
    padding-top: 15%;
    margin-left: -10%;
}
.forget .daja-image .logoDv figure img {
    width: 160px;
}
.forget .dajaFrom {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.forget .dajaFrom .logoDv figure img {
    width: 160px;
}
.forget .dajaFrom h2.title {
    font-weight: 600;
    padding-bottom: 80px;
}
.forget .dajaFrom .textDv {
    padding-bottom: 30px;
}
.forget .dajaFrom .textDv h4.bld {
    font-weight: 500;
}
.forget .dajaFrom .memberLink p a {
    font-size: 18px;
    margin: 0 10px;
}
.forget .dajaFrom .form-group .form-control {
    border: 1px solid #0000002b;
    border-radius: 50px;
    padding: 12px 30px;
    color: #636060;
}
.forget .dajaFrom .form-group .form-control:-ms-input-placeholder {
    opacity: 0.5;
}
.forget .dajaFrom .form-group .form-control::placeholder {
    opacity: 0.5;
}
.forget .dajaFrom .form-group button.btn {
    background: #FF9517;
    background: var(--first-color);
    color: #fff;
    font-family: 'Poppins';
    padding: 12px 0;
    transition: 0.6s;
    border: 2px solid #FF9517;
    border: 2px solid var(--first-color);
}
.forget .dajaFrom .form-group button.btn:hover {
    background: transparent;
    color: #FF9517;
    color: var(--first-color);
}
.forget .dajaFrom .privacyDv {
    padding-top: 100px;
}
.forget .dajaFrom .privacyDv h5 {
    color: #636060;
}
/* Signin Css Start Here */

/* Media Query Start Here */
@media (max-width: 1101px) {
    .forget .dajaFrom .privacyDv {
        padding-top: 20px;
    }   
}
@media (max-width: 992px) {
    .forget .daja-image {
        display: none;
    }
}
@media (max-width: 575px) {
    .forget .dajaFrom {
        padding: 0 20px;
    }
    .forget .dajaFrom h2.title {
        padding-bottom: 20px;
    }
    .forget .dajaFrom .privacyDv h5 {
        font-size: 12px;
    }
    .forget .dajaFrom .privacyDv h5 a {
        font-size: 12px;
    }
    .forget .dajaFrom h2.title {
        font-size: 32px;
    }
    .forget .dajaFrom h4 {
        font-size: 19px;
        line-height: 29px;
    }
}
@media (max-width: 400px) {
    .forget .dajaFrom .form-group .form-control {
        padding: 10px 20px;
    }
    .forget .dajaFrom .form-group button.btn {
        padding: 10px 0;
    }
    .forget .dajaFrom h4 {
        font-size: 14px;
        line-height: 21px;
    }
    .forget .dajaFrom .memberLink p {
        font-size: 13px;
    }
    .forget .dajaFrom .memberLink a {
        font-size: 13px;
    }
}
/* Media Query End Here */
/* Poppins Font Family */
/* Poppins Font Family */

:root {
    --first-color:#FF9517;
    --second-color:#0282A5;
    --third-color:#000;
    --fourth-color:#fff;
}
.blueish {
    color: #0282A5 !important;
    color: var(--second-color) !important;
}
h1,h2,h3,h4,h5,h6 {
    font-family: 'Poppins';
}
h2 {
    font-size: 44px;
    font-weight: 500;
}
h3 {
    font-size: 25px;
    font-weight: 400;
}
h5,label {
    font-size: 15px;
    font-weight: 400;
}
a {
    font-size: 15px;
    font-weight: 500;
    text-decoration: none !important;
}
p {
    font-family: 'Poppins';
    font-size: 18px;
    font-weight: 500;
}

/* Signin Css Start Here */
.createpassword {
    height: 100vh;
    overflow: hidden;
}
.createpassword .daja-image {
    height: 100vh;
    background-image: url(/static/media/back.a0d2247f.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.createpassword .daja-image .logoDv {
    text-align: center;
    padding-top: 15%;
    margin-left: -10%;
}
.createpassword .daja-image .logoDv figure img {
    width: 160px;
}
.createpassword .dajaFrom {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.createpassword .dajaFrom .logoDv figure img {
    width: 160px;
}
.createpassword .dajaFrom h2.title {
    font-weight: 600;
    padding-bottom: 80px;
}
.createpassword .dajaFrom .textDv {
    padding-bottom: 30px;
}
.createpassword .dajaFrom .textDv h4.bld {
    font-weight: 500;
}
.createpassword .dajaFrom .memberLink p a {
    font-size: 18px;
    margin: 0 10px;
}
.createpassword .dajaFrom .form-group .form-control {
    border: 1px solid #0000002b;
    border-radius: 50px;
    padding: 12px 30px;
    color: #636060;
}
.createpassword .dajaFrom .form-group .form-control:-ms-input-placeholder {
    opacity: 0.5;
}
.createpassword .dajaFrom .form-group .form-control::placeholder {
    opacity: 0.5;
}
.createpassword .dajaFrom .form-group button.btn {
    background: #FF9517;
    background: var(--first-color);
    color: #fff;
    font-family: 'Poppins';
    padding: 12px 0;
    transition: 0.6s;
    border: 2px solid #FF9517;
    border: 2px solid var(--first-color);
}
.createpassword .dajaFrom .form-group button.btn:hover {
    background: transparent;
    color: #FF9517;
    color: var(--first-color);
}
.createpassword .dajaFrom .privacyDv {
    padding-top: 100px;
}
.createpassword .dajaFrom .privacyDv h5 {
    color: #636060;
}
/* Signin Css Start Here */

/* Media Query Start Here */
@media (max-width: 1101px) {
    .createpassword .dajaFrom .privacyDv {
        padding-top: 20px;
    }   
}
@media (max-width: 992px) {
    .createpassword .daja-image {
        display: none;
    }
}
@media (max-width: 575px) {
    .createpassword .dajaFrom {
        padding: 0 20px;
    }
    .createpassword .dajaFrom h2.title {
        padding-bottom: 20px;
    }
    .createpassword .dajaFrom .privacyDv h5 {
        font-size: 12px;
    }
    .createpassword .dajaFrom .privacyDv h5 a {
        font-size: 12px;
    }
    .createpassword .dajaFrom h2.title {
        font-size: 32px;
    }
    .createpassword .dajaFrom h4 {
        font-size: 19px;
        line-height: 29px;
    }
}
@media (max-width: 767px) {
    h3 {
        font-size: 18px;
    }
    p {
        font-size: 14px;
    }
}

@media (max-width: 400px) {
    .createpassword .dajaFrom .form-group .form-control {
        padding: 10px 20px;
    }
    .createpassword .dajaFrom .form-group button.btn {
        padding: 10px 0;
    }
    .createpassword .dajaFrom h4 {
        font-size: 14px;
        line-height: 21px;
    }
    .createpassword .dajaFrom .memberLink p {
        font-size: 13px;
    }
    .createpassword .dajaFrom .memberLink a {
        font-size: 13px;
    }
}
@media (max-width: 425px) {
    p {
        font-size: 10px;
    }
}


/* Media Query End Here */


.distance-width {
  width: 675px;
}
.navBar-CustomToggle{
    display: none;
  }
  a {
      transition: background 0.2s, color 0.2s;
    }
    a:hover,
    a:focus {
      text-decoration: none;
    }
    
    #wrapper {
      padding-left: 0;
      transition: all 0.5s ease;
      position: relative;
    }
    
    #sidebar-wrapper {
      z-index: 1000;
      /* position: fixed; */
      position: absolute;
      left: 250px;
      width: 0;
      height: 100%;
      margin-left: -250px;
      overflow-y: auto;
      overflow-x: hidden;
      background: #FFFFFF;
      /* border: 1px solid #38436424; */
      border-right: 1px solid #38436424;
      transition: all 0.5s ease;
    }
    #sidebar-wrapper .profileBox{
      /* display: flex;
      justify-content: center;
      align-items: center; */
    }
    
    #sidebar-wrapper .profile-Container{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 24px;
    }
    #sidebar-wrapper .profile-img{
      width: 65px;
      height: 65px;
      background: #919191;
      border-radius: 50%;
      overflow: hidden;
    }
    #sidebar-wrapper .user-name{
      color: #384364;
      font-size: 20px;
      font-family: Poppins-Bold;
      text-align: center;
      margin-top: 8px;
    }
    #sidebar-wrapper .user-email{
      color: #384364;
      font-size: 14px;
      font-family: Poppins-Regular;
      text-align: center;
      margin-top: -8px;
    }
    
    #wrapper.toggled #sidebar-wrapper {
      width: 250px;
    }
    
    .sidebar-brand {
      position: absolute;
      top: 0;
      width: 250px;
      text-align: center;
      padding: 20px 0;
    }
    .sidebar-brand h2 {
      margin: 0;
      font-weight: 600;
      font-size: 24px;
      color: #fff;
    }
    
    .sidebar-nav {
      position: absolute;
      top: 70px;
      width: 250px;
      margin: 0;
      padding: 0;
      list-style: none;
    }
    .sidebar-nav > li {
      text-indent: 10px;
      line-height: 42px;
      margin-bottom: 14px;
    }
    .sidebar-nav > li a {
      display: block;
      text-decoration: none;
      color: #0282A5;
      font-weight: 600;
      font-size: 12px;
      margin: 0px 14px;
    }
    .sidebar-nav > li > a:hover,
    .sidebar-nav > li.active > a {
      text-decoration: none;
      color: #FFFFFF;
      background: #FF9517;
      margin: 0px 14px;
      border-radius: 12px;
    }
    .sidebar-nav > li > a i.fa {
      font-size: 24px;
      width: 60px;
    }
    
    #navbar-wrapper {
      width: 100%;
      position: absolute;
      z-index: 2;
      border-bottom: 1px solid #70707029;
  }
    #wrapper.toggled #navbar-wrapper {
        position: absolute;
        margin-right: -250px;
    }
    #navbar-wrapper .navbar {
      border-width: 0 0 0 0;
      background-color: #FFFFFF;
      font-size: 24px;
      margin-bottom: 0;
      border-radius: 0;
      /* height: 68px; */
    }
    #navbar-wrapper .navbar a {
      color: #0282A5;
    }
    #navbar-wrapper .navbar a:hover {
      color: #FF9517;
    }
  
  #navbar-wrapper .navbar a:active {
      color: #FF9517 !important;
  }
    
    #navbar-wrapper .dashboard-Logo img {
      height: 22px;
      margin-left: 62px;
    }
    
    #content-wrapper {
      width: 100%;
      height: 94vh;
      position: absolute;
      /* padding: 15px; */
      padding-left: 250px;
      padding-right: 120px;
      top: 100px;
      background-color:#F6F6F8 ;
    }
    #wrapper.toggled #content-wrapper {
      position: absolute;
      margin-right: -250px;
    }
  
  
    .sidebar-nav > li .card .card-header > button {
      display: block;
      text-decoration: none;
      color: #A2A2A2;
      font-weight: 600;
      font-size: 18px;
      margin: 0px 14px;
  }
  .sidebar-nav > li .card .card-header > button:hover {
    text-decoration: none;
    color: #5C3DA4;
    background: #E4E1ED;
    margin: 0px 1px;
    border-radius: 12px;
  }
  .sidebar-nav > li .card .card-header > button:hover .accordian-icon {
    margin-left: 3px;
  }
  
  .sidebar-nav > li .card .card-header > button:focus {
    box-shadow: none;
  }
  
  
  .sidebar-nav .accordian-icon{
    /* margin-left: 3px; */
    margin-left: -10px;
    font-size: 24px;
    width: 60px;
  }
  .sidebar-nav .accordian-items{
    font-size: 14px;
    color: #121212;
    font-family: Poppins-Medium;
    height: 22px;
  }
  
  /* .sidebar-nav > li:active > .card .card-header {
    text-decoration: none;
    color: #5C3DA4;
    background: #E4E1ED;
    margin: 0px 14px;
    border-radius: 12px;
  } */
  
  .dropdown-item.active, .dropdown-item:active {
    color: #000 !important;
    text-decoration: underline !important;
    background-color: transparent !important;
  }
  .dropdown-item.active, .dropdown-item:hover {
    color: #000 !important;
    text-decoration: underline !important;
    background-color: transparent !important;
  }
  .dropdown-item:focus, .dropdown-item:focus {
    background-color: transparent !important;
  }
  .dropdown-item:focus, .dropdown-item:hover {
    background-color: transparent !important;
  }
  
  .accordion .card{
    border: none !important;
  }
  .accordion .card .card-header{
    border-bottom: none !important; 
  }
  

  
    @media (min-width: 1024px) {
      #sidebar-wrapper{
        overflow-y: scroll;
        /* height: 91%; */
        max-height: 92vh;
      }
      #content-wrapper {
        height: 92vh;
        overflow-y: scroll;
      }
      .distance-width {
        width: 550px;
      }
      
    }

    @media (min-width: 768px) and (max-width: 1024px) {
      .distance-width {
        /* width: 500px; */
        width: 300px;
      }
      #content-wrapper {
        padding-right: 90px;
      }
    }
    @media (max-width: 992px) {
      .distance-width {
        width: 250px;
      }
    }
    /* @media (min-width: 769px) and (max-width: 870px) {
      .distance-width {
        width: 250px;
      }
    } */
  
    @media (min-width: 992px) {
      #wrapper {
        /* padding-left: 250px; */
      }
      
      #wrapper.toggled {
        padding-left: 60px;
      }
    
      #sidebar-wrapper {
        width: 250px;
      }
      
      #wrapper.toggled #sidebar-wrapper {
        width: 76px;
      }
      
      #wrapper.toggled #navbar-wrapper {
        position: absolute;
        margin-right: -190px;
    }
      
      #wrapper.toggled #content-wrapper {
        position: absolute;
        margin-right: -190px;
      }
    
      #navbar-wrapper {
        position: relative;
      }
    
      #wrapper.toggled {
        padding-left: 0px;
      }
    
      #content-wrapper {
        position: relative;
        top: 0;
        /* padding-left: 269px;
        padding-right: 269px */
      }
    
      #wrapper.toggled #navbar-wrapper,
      #wrapper.toggled #content-wrapper {
        position: relative;
        margin-right: 60px;
      }
     
    }
    
    @media (min-width: 768px) and (max-width: 991px) {
      .navBar-CustomToggle{
        display: block;
      }
      #wrapper {
        /* padding-left: 60px; */
        padding-left: 0px;
      }
  
      #sidebar-wrapper {
        /* width: 60px; */
        width: 0px;
        height: 111vh;
      }
      #wrapper.toggled #sidebar-wrapper {
        width: 250px;
        height: 118vh;
    }
      
    #wrapper.toggled #navbar-wrapper {
        position: absolute;
        margin-right: -250px;
    }
      
      #wrapper.toggled #content-wrapper {
        position: absolute;
        margin-right: -250px;
      }
    
      #navbar-wrapper {
        /* position: relative; */
        position: fixed;
      }
    
      #wrapper.toggled {
        /* padding-left: 250px; */
        padding-left: 0px;
      }
    
      #content-wrapper {
        position: relative;
        top: 0;
        height: 100%;
        /* height: 118vh; */
      }
    
      #wrapper.toggled #navbar-wrapper,
      #wrapper.toggled #content-wrapper {
        position: relative;
        margin-right: 250px;
            /* height: 112vh; */
      }
    }

    @media (max-width: 768px) {
      .distance-width {
        width: 262px;
      }
      #content-wrapper {
        /* top: 50px; */
        padding-left: 70px;
        padding-right: 70px;
      }
    }
    
    @media (max-width: 767px) {
      .navBar-CustomToggle{
        display: block;
      }
      #wrapper {
        padding-left: 0;
      }
    
      #sidebar-wrapper {
        width: 0;
      }
    
      #wrapper.toggled #sidebar-wrapper {
        width: 250px;
      }
      #wrapper.toggled #navbar-wrapper {
        position: absolute;
        margin-right: -250px;
      }
    
      #wrapper.toggled #content-wrapper {
        position: absolute;
        margin-right: -250px;
      }
    
      #navbar-wrapper {
        position: fixed;
      }
    
      #wrapper.toggled {
        padding-left: 0px;
      }
    
      #content-wrapper {
        position: relative;
        top: 0;
        height: 100%;
        overflow-y: scroll;
      }
    
      #wrapper.toggled #navbar-wrapper,
      #wrapper.toggled #content-wrapper {
        position: relative;
        margin-right: 250px;
      }
    }
  
    @media (max-width: 425px) {
      #navbar-wrapper .dashboard-Logo img {
        margin-left: 0px;
      }
      .navbar-title {
        font-size: 16px;
      }
      #content-wrapper {
        padding-left: 25px;
        padding-right: 25px;
    }
    }
  
    @media (max-width: 375px) {
      .navbar-title {
        font-size: 12px;
      }
      .Home-main-Container .job-title-provider {
        font-size: 26px;
      }
    }
  
    @media (max-width: 320px) {
      #navbar-wrapper .dashboard-Logo img {
        height: 14px;
        margin-bottom: 9px;
      }
      .navbar-title {
        font-size: 10px;
        margin-bottom: 0px;
      }
      #content-wrapper {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  
  
  
body{
    overflow-x: hidden;
}
.Active{
    color: #FF9517;
}
.sideBar-icon{
    margin-right: 40px;
}
/* .custom-topBar{
    margin-left: 855px;
} */
.notification-icon{
    color: #0282A5;
}
.notification-icon:hover{
    color: #FF9517;
}
.dashboard-title {
    color: #0282A5;
    font-family: Poppins-SemiBold;
    font-size: 40px;
    text-align: center;
    margin-bottom: 45px;
}

.service-Container{
    padding: 20px 10px;
}

.service-Container .serviceCard {
    background-color: #fff;
    width: 100%;
    /* height: 182px; */
    padding: 20px 55px;
    border-radius: 12px;
    text-align: center;
    margin-bottom: 18px;
    box-shadow: 4px 5px 5px 5px #e3e3e3;
}

.service-Container .serviceCard:hover {
    box-shadow: 4px 5px 5px 5px #6c6b6b52;
}

.service-Container .serviceTitle {
    font-family: Poppins-Medium;
    font-size: 15px;
    color: #000000;
    font-weight: 600;
    margin-top: 14px;
}

.service-Container .serviceDescription {
    font-size: 10px;
    font-family: Poppins-Regular;
    color: #000000;
    text-align: center;
}

.service-Container .serviceCard-img{
    height: 40px;
    /* width: 40px; */
    text-align: center;
}

.service-Container .serviceCard-img img{
    width: 48px;
}
.icon-container {
    margin-top: -10px;
}

.service-Container .service-icon{
    color: #000000;
    font-size: 12px;
}

.btn.btn-primary {
    background: #FF9517 !important;
    /* background: linear-gradient(
    -45deg, #1391a5, #274685); */
    color: #fff;
    font-size: 12px;
    border-radius: 50px;
    box-shadow: 0 10px 30px 0 rgb(0 0 0 / 15%);
    width: 200px;
    padding: 12px 30px !important;
}

.btn-container{
    margin-top: 40px;
    margin-bottom: 10px;
    text-align: center;
}

.form-InnerTitle {
    color: #000000;
    font-size: 14px;
    font-family: Poppins-SemiBold;
}

select.form-control:not([size]):not([multiple]) {
    /* height: calc(2.25rem + 2px); */
    height: 40px !important;
}

/* == Employment Opportunity == */
.EmploymentOpt-Container {
    padding: 20px 10px;
}
.EmploymentOpt-Container .serviceCard {
    background-color: #fff;
    width: 100%;
    height: auto;
    padding: 44px 44px;
    border-radius: 12px;
    text-align: center;
    margin-bottom: 18px;
    box-shadow: 4px 5px 5px 5px #e3e3e3;
}
.EmploymentOpt-Container .search-input {
    border-radius: 50px 0px 0px 50px;
    padding: 9px 30px !important;
    width: 60%;
    outline: 0;
    border: 1px solid #7676766b;
    font-size: 13px;
    margin-right: 40px;
}

.EmploymentOpt-Container .search-btn {
    background: #FF9517;
    color: #fff;
    text-align: center;
    padding: 6px 30px !important;
    vertical-align: middle;
    -webkit-user-select: none;
    -ms-user-select: none;
        user-select: none;
    border: 1px solid transparent;
    line-height: 1.5;
    border-radius: 0px 50px 50px 0px;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    margin-left: -30px;
}

.EmploymentOpt-Container .jobCard-Dropdown{
    padding: 9px 30px !important;
    outline: 0;
    border: 1px solid #7676766b;
    font-size: 13px;
    border-radius: 50px;
}
.EmploymentOpt-Container select.form-control:not([size]):not([multiple]) {
    height: unset !important;
}
.EmploymentOpt-Container .searchCount-result {
    font-size: 12px;
    color: #636060e8;
    font-family: Poppins-Regular;
}

.EmploymentOpt-Container .search-Job-container{
    border-top: 2px solid #70707040;
    padding: 28px 28px;
}  

.EmploymentOpt-Container .job-title {
    font-size: 18px;
    font-family: Poppins-Medium;
    color: #000000;
    text-align: left;
    text-align: initial;
    margin-bottom: 18px;
    margin-top: 20px;
}
.EmploymentOpt-Container .job-desc {
    font-size: 14px;
    font-family: Poppins-Regular;
    color: #000000;
    text-align: left;
    text-align: initial;
}

.EmploymentOpt-Container .job-address {
    font-size: 12px;
    font-family: Poppins-Regular;
    color: #636060d9;
    text-align: end;
    margin: 0px;
}
.EmploymentOpt-Container .searchjob-icon{
    text-align: end;
    margin: 12px 0px;
}

.EmploymentOpt-Container .Searchjob-btn{
    padding: 7px 25px !important;
    font-size: 12px;
}

.EmploymentOpt-Container .feature-tag{
    background-color: #fd8b5621;
    color: #FF844B;
    padding: 10px;
    width: 100px;
    font-size: 12px;
}

.EmploymentOpt-Container .searchjob-scroll{
    height: 455px;
    overflow-y: scroll;
}


/* == selected Employment Opportunity == */
.Employment-Container {
    padding: 20px 10px;
}
.Employment-Container .serviceCard {
    background-color: #fff;
    width: 100%;
    height: 505px;
    padding: 44px 82px;
    border-radius: 12px;
    text-align: center;
    margin-bottom: 18px;
    box-shadow: 4px 5px 5px 5px #e3e3e3;
}

.Employment-Container .job-para{
    color: #000000;
    font-family: Poppins-Light;
    font-size: 14px;
    text-align: left;
    text-align: initial;
}

.Employment-Container .EmploymentTitle{
    font-size: 20px;
    font-family: Poppins-SemiBold;
    color: #000000;
}

.Employment-Container .jobDesc-title{
    font-size: 16px;
    font-family: Poppins-Medium;
    color: #000000;
    text-align: left;
    text-align: initial;
}

.Employment-Container .feature-tag{
    background-color: #fd8b5621;
    color: #FF844B;
    padding: 10px;
    width: 100px;
}
.Employment-Container .job-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
    margin-top: 50px;
}
.Employment-Container .address{
    font-size: 12px;
    font-family: Poppins-Regular;
    margin: 0px;
}
.Employment-Container .starIcon{
    margin-right: 40px;
}

.flexBox-style{
    display: flex;
    justify-content: center;
    align-items: center;
}

 /* == Employment Form == */
 .EmploymentForm-Container {
    padding: 20px 10px;
}
.EmploymentForm-Container .serviceCard {
    background-color: #fff;
    width: 100%;
    /* height: 505px; */
    padding: 44px 82px;
    border-radius: 12px;
    text-align: center;
    margin-bottom: 18px;
    box-shadow: 4px 5px 5px 5px #e3e3e3;
}
.EmploymentForm-Container .uploadContainer  {
    position: relative;
    overflow: hidden;
    display: inline-block;
  }
.EmploymentForm-Container .uploadContainer  input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}
.EmploymentForm-Container .subTitle{
    color: #000000;
    font-size: 14px;
    font-family: Poppins-Regular;
    margin-bottom: 10px;
}

/* == contractor  == */
.contractor-Container {
    padding: 20px 10px;
}
.contractor-Container .serviceCard {
    background-color: #fff;
    width: 100%;
    height: auto;
    padding: 44px 30px;
    border-radius: 12px;
    text-align: center;
    margin-bottom: 18px;
    box-shadow: 4px 5px 5px 5px #e3e3e3;
}
.contractor-Container .contractor-desc{
    font-size: 16px;
    font-family: Poppins-Regular;
    color: #636060;
    text-align: left;
    text-align: initial;
}

.contractor-Container .jobCard-Dropdown{
    padding: 9px 40px !important;
    outline: 0;
    border: 1px solid #7676766b;
    font-size: 13px;
    border-radius: 50px;
    width: auto;
}
.contractor-Container .search-innerContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 28px;
}
.contractor-Container select.form-control:not([size]):not([multiple]) {
    height: unset !important;
}

.contractor-Container .card-Title{
    font-size: 20px;
    font-family: Poppins-SemiBold;
    color: #000000;
}

.contractor-Container  .contractor-Card {
    background-color: #fff;
    width: 100%;
    height: auto;
    padding: 20px 19px;
    border-radius: 12px;
    text-align: center;
    box-shadow: 4px 5px 5px 5px #e3e3e3;
}

.contractor-Container .cont-card-title{
    color: #000000;
    font-family: Poppins-SemiBold;
    font-size: 16px;
}
.contractor-Container .cont-card-detail{
    font-size: 10px;
    color: #000000;
    font-family: Poppins-Regular;
}

.contractor-Container .viewDetail{
    color: #0282A5;
    font-size: 14px;
    font-family: Poppins-Regular;
    text-decoration: underline;
}

.contractor-Container .search-btn {
    padding: 8px 30px !important;
}

/* === PaymentMethod === */
.PaymentMethod-Container {
    padding: 20px 10px;
}
.PaymentMethod-Container .serviceCard {
    background-color: #fff;
    width: 100%;
    /* height: 505px; */
    padding: 44px 82px;
    border-radius: 12px;
    text-align: center;
    margin-bottom: 18px;
    box-shadow: 4px 5px 5px 5px #e3e3e3;
}
.PaymentMethod-Container .subTitle{
    font-size: 20px;
    font-family: 'Poppins-SemiBold';
    color: #000000;
}
.PaymentMethod-Container .paymentCard {
    font-size: 10px;
    color: #000000;
    margin: 0px;
}
.PaymentMethod-Container .paymentCard-num {
    font-size: 20px;
    color: #343A40;
    margin: 0px;
}

.PaymentMethod-Container .check-Icon{
    color: #FF9517;
}
.PaymentMethod-Container .icon-delete{
   font-size: 20px;
}
.PaymentMethod-Container .icon-delete :hover{
    color:#FF9517;
}

/* == DriverRating == */
.DriverRating-Container {
    padding: 20px 10px;
}
.DriverRating-Container .serviceCard {
    background-color: #fff;
    width: 100%;
    /* height: 505px; */
    padding: 44px 82px;
    border-radius: 12px;
    text-align: center;
    margin-bottom: 18px;
    box-shadow: 4px 5px 5px 5px #e3e3e3;
}
.DriverRating-Container .profile{
    height: 120px;
    width: 120px;
    border-radius: 50%;
    border: 3px solid #FF9517;
    margin-bottom: 20px;
    overflow: hidden;
}

.DriverRating-Container .DriverName{
    color: #0282A5;
    font-size: 20px;
    font-family: Poppins-SemiBold;
    margin: 0px;
}

.DriverRating-Container .type {
    font-family: Poppins-Regular;
    font-size: 14px;
    margin-bottom: 16px;
}

.DriverRating-Container .status {
    font-family: Poppins-Regular;
    font-size: 11px;
    margin-top: 7px;
}

 

/* == order Info == */
.OrderInformation-Container {
    padding: 20px 10px;
}
.OrderInformation-Container .serviceCard {
    background-color: #fff;
    width: 100%;
    /* height: 505px; */
    padding: 44px 82px;
    border-radius: 12px;
    text-align: center;
    margin-bottom: 18px;
    box-shadow: 4px 5px 5px 5px #e3e3e3;
}
.OrderInformation-Container .subtitle{
    font-size: 24px;
    color: #000000;
    font-family: Poppins-SemiBold;
    margin: 0px;
}

.OrderInformation-Container .orderNum{
    color: #0282A5;
    font-size: 16px;
    font-family: Poppins-SemiBold;
}

.OrderInformation-Container .detail-title{
    font-size: 15px;
    font-family: Poppins-SemiBold;
    color: #000000;
}

.OrderInformation-Container .address{
    font-size: 15px;
    font-family: Poppins-Regular;
    color: #000000;
    margin-left: 20px;
}
.OrderInformation-Container  .Address-Container{
    display: flex;
    justify-content: unset;
    align-items: center;
}
.OrderInformation-Container .pickupTitle{
    font-size: 15px;
    color: #000000;
    font-family: Poppins-SemiBold;
    text-align: left;
    text-align: initial;
}
.OrderInformation-Container .pickup{
    color: #000000;
    font-size: 13px;
    font-family: Poppins-Regular;
    text-align: left;
    text-align: initial;
    margin-left: 10px;
}

.OrderInformation-Container .Amount {
    color: #FF9517;
    font-size: 27px;
    font-family: Poppins-SemiBold;
    margin-top: 44px;
}
.OrderInformation-Container .serviceType {
    color: #000000;
    font-family: 'Poppins-SemiBold';
    font-size: 20px;
}

/* == get Quote == */
.getQuote-Container {
    padding: 20px 10px;
}
.getQuote-Container .serviceCard {
    background-color: #fff;
    width: 100%;
    padding: 44px 82px;
    border-radius: 12px;
    text-align: center;
    margin-bottom: 18px;
    box-shadow: 4px 5px 5px 5px #e3e3e3;
}
.getQuote-Container input{
    border-radius: 30px;
    border: 1px solid #00000036;
    padding: 10px 38px;
    height: 38px;
}
.getQuote-Container textarea{
    border-radius: 30px;
    border: 1px solid #00000036;
    padding: 10px 38px;
    height: 200px;
}
.getQuote-Container :-ms-input-placeholder{
    font-size: 13px;
 }
.getQuote-Container ::placeholder{
    font-size: 13px;
 } 

.getQuote-Container select.form-control:not([size]):not([multiple]) {
    height: 38px !important;
    border-radius: 30px;
    padding: 10px 38px;
    font-size: 13px;
}
.flexBox-start{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}


/* == nav == */
.nav_logo {
    /* margin-left: 100px; */
    margin-left: 50px;
}

.nav_logo img {
    width: 40px;
}

.profile{
    height: 30px;
    width: 30px;
    border-radius: 8px;
    overflow: hidden;
}

.profile-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 50px;
}

.navbar-profile-name {
    font-size: 13px;
    color: #4E7D96;
    font-family: Poppins-Bold;
    margin: 0px;
}
.navbar-profile-name:hover {
  text-decoration: underline;
}
.navbar-profile-role {
    font-size: 10px;
    color: #4E7D96;
    font-family: Poppins-Regular;
    margin: 0px;
}

.custom-topBar ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  
.custom-topBar li {
    display: inline;
    padding: 0px 16px;
    font-size: 16px;
  }

.flexBox-style{
    display: flex;
    justify-content: center;
    align-items: center;
}
.flexBox-space{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.image-width{
    width: 100%;
}

.employment-btnContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
/* .profile-container {
    padding-right: 30px;
} */

.EmploymentTitle{
    color: #000000;
    font-family: Poppins-SemiBold;
    font-size: 16px;
}

.payment-gallery{
    display: flex;
    justify-content: center;
    align-items: center;
}

.term input {
    height: 40px;
    width: 50%;
    padding: 2px 20px;
    border-radius: 10px;
}
.term textarea {
    height: 100px;
    width: 50%;
    padding: 18px 20px;
    border-radius: 10px;
}

@media (min-width: 768px) and (max-width: 1024px) {
    .dashboard-title {
      font-size: 32px;
    }
    /* .profile-container {
        margin-right: 0px !important;
      } */

    .placeOrder-Container .formCard {
        padding: 20px 30px;
    } 
    .PaymentMethod-Container .serviceCard {
        padding: 44px 40px;
    }
    .OrderInformation-Container .serviceCard {
        padding: 44px 40px;
    }
    .OrderInformation-Container .detail-title {
        font-size: 12px;
        margin: 4px;
    }
    .OrderInformation-Container .address {
        font-size: 13px;
        text-align: left;
        text-align: initial;
    }
    .OrderInformation-Container .pickupTitle {
        font-size: 14px;
    }
    .EmploymentOpt-Container .serviceCard {
        padding: 44px 40px;
    }
    .EmploymentOpt-Container .search-btn {
        padding: 6px 12px !important;
    }
    .EmploymentOpt-Container .jobCard-Dropdown {
        padding: 9px 18px !important;
    }
    .EmploymentOpt-Container .Searchjob-btn {
        padding: 7px 20px !important;
    }
    .EmploymentOpt-Container .job-desc {
        font-size: 13px;
    }
    .EmploymentOpt-Container .job-title {
        font-size: 17px;
        margin-bottom: 12px;
    }
    .contractor-Container .contractor-desc {
        font-size: 14px;
    }
    .contractor-Container .search-innerContainer {
        padding: 5px 12px;
    }
    .contractor-Container .jobCard-Dropdown {
        padding: 9px 24px !important;
    }
    .contractor-Container .contractor-Card {
        padding: 20px 14px;
    }
    .contractor-Container .cont-card-title {
        font-size: 13px;
    }
    .contractor-Container .viewDetail {
        font-size: 13px;
    }
  }

@media (max-width: 768px) {
    .distance-width {
      /* width: 262px; */
      width: 98px;
    }
    .nav_logo {
        margin-left: 0px;
    }
    .service-Container .serviceCard {
        padding: 20px 32px;
    }
    .dashboard-title {
        margin-top: 58px;
    }
    .placeOrder-Container .inputForm-padding {
        padding: 35px 40px;
    }
    .orderTracking-Container .serviceCard {
        margin-bottom: 214px;
    }
    .EmploymentOpt-Container .search-btn {
        padding: 6px 6px !important;
    }
    .EmploymentOpt-Container .search-Job-container {
        padding: 28px 12px;
    }
    .EmploymentOpt-Container .job-desc {
        font-size: 12px;
    }
    .EmploymentOpt-Container .feature-tag {
        padding: 8px;
    }
    .employment-btnContainer {
        display: block;
    }
    .contractor-Container .search-innerContainer {
        padding: 5px 0px;
    }
    .contractor-Container .jobCard-Dropdown {
        padding: 9px 22px !important;
    }
    .contractor-Container .search-btn {
        padding: 8px 20px !important;
    }
    .Report-Container .serviceCard {
        margin-bottom: 160px;
    }
}
  
@media (max-width: 767px) {
    .EmploymentOpt-Container .search-btn {
        padding: 6px 30px !important;
    }
    .track-package-section{
        margin-bottom: 20px;
    }
    .contractor-Container .contractor-desc {
        font-size: 14px;
    }
    .dashboard-title {
        font-size: 34px;
    }
    .contractor-Container .contractor-Card {
       margin-bottom: 24px;
    }
}
@media (max-width: 425px) {
    .nav_logo {
        margin-left: 0px;
    }
    .distance-width {
        width: 0px;
    }
    .profile-container {
        margin-right: 0px;
    }
    .placeOrder-Container .formCard {
        padding: 20px 12px;
    }
    .dashboard-title {
        font-size: 28px;
        margin-bottom: 32px;
    }
    .OrderInformation-Container .serviceCard {
        padding: 44px 28px;
    }
    .OrderInformation-Container .orderNum {
        color: #0282A5;
        font-size: 14px;
    }
    .OrderInformation-Container .subtitle {
        font-size: 20px;
    }
    .OrderInformation-Container .address {
        text-align: left;
        text-align: initial;
    }
    .orderTracking-Container .tracking-points {
        font-size: 13px;
        text-align: left;
        text-align: initial;
    }
    .orderTracking-Container .serviceCard {
        padding: 44px 28px;
    }
    .getQuote-Container .serviceCard {
        padding: 44px 28px;
    }
    .EmploymentOpt-Container .serviceCard {
        padding: 44px 28px;
    }
    .EmploymentOpt-Container .search-btn {
        padding: 6px 12px !important;
    }
    .EmploymentOpt-Container .searchCount-result {
        font-size: 10px;
    }
    .EmploymentOpt-Container .Searchjob-btn {
        padding: 7px 40px !important;
    }
    .EmploymentOpt-Container .job-title {
        font-size: 16px;
        margin-bottom: 12px;
        margin-top: 18px;
    }
    .contractor-Container .serviceCard {
        padding: 44px 28px;
    }
    .contractor-Container .contractor-desc {
        margin-top: 8px;
    }
    .contractor-Container .search-innerContainer {
        display: block;
    }
    .contractor-Container select.form-control:not([size]):not([multiple]) {
        width: 80%;
    }
    .contractor-Container .search-btn {
        padding: 8px 60px !important;
    }
    .contractor-Container .cont-card-detail {
        font-size: 12px;
    }
    .Report-Container .serviceCard {
        padding: 44px 28px;
        margin-bottom: 18px;
    }
    .RiderTracking-Container .serviceCard {
        padding: 44px 28px;
    }
    .orderTracking-Container .orderTrack-icon {
        font-size: 22px;
    }
    .DriverRating-Container .serviceCard {
        padding: 44px 28px;
    }
    .payment-gallery {
        display: block;
    }
}

@media (max-width: 375px) { 
    .dashboard-title {
        font-size: 20px;
    }
    .service-Container .serviceDescription {
        font-size: 12px;
    }
    .placeOrder-Container .inputForm-padding {
        padding: 35px 24px;
    }
    .PaymentMethod-Container .serviceCard {
        padding: 44px 24px;
    }
    .OrderInformation-Container .serviceCard {
        padding: 44px 20px;
    }
    .orderTracking-Container .serviceCard {
        padding: 44px 20px;
    }
    .getQuote-Container .serviceCard {
        padding: 44px 20px;
    }
    .EmploymentOpt-Container .serviceCard {
        padding: 44px 20px;
    }
    .contractor-Container .serviceCard {
        padding: 44px 20px;
    }
    .Report-Container .serviceCard {
        padding: 44px 20px;
    }
    .RiderTracking-Container .serviceCard {
        padding: 44px 20px;
    }
    .nav_logo img {
        width: 32px;
    }
    .custom-topBar li {
        padding: 0px 12px;
    }
    #navbar-wrapper .navbar a {
        font-size: 10px;
    }
}

@media (max-width: 320px) {
    .dashboard-title {
        margin-bottom: 20px;
    }
    .placeOrder-Container .subTitle {
        font-size: 15px;
    }
    .PaymentMethod-Container .subTitle {
        font-size: 14px;
    }
    .OrderInformation-Container .subtitle {
        font-size: 16px;
    }
    .OrderInformation-Container .serviceType {
        font-size: 16px;
    }
    .EmploymentOpt-Container .search-btn {
        padding: 6px 8px !important;
    }
    .contractor-Container .contractor-desc {
        font-size: 12px;
    }
    .contractor-Container .cont-card-title {
        font-size: 14px;
    }
    .navbar-profile-role {
        font-size: 8px;
    }
    .navbar-profile-name {
        font-size: 10px;
    }
    .profile {
        height: 20px;
        width: 20px;
    }
    .nav_logo img {
        width: 30px;
    }
    .orderTracking-Container :-ms-input-placeholder{
        font-size: 12px;
    }
    .orderTracking-Container ::placeholder{
        font-size: 12px;
    }
}

/* == Invoice == */
.Invoice-Container {
    padding: 20px 10px;
}
.Invoice-Container .serviceCard {
    background-color: #fff;
    width: 100%;
    height: auto;
    padding: 44px 26px;
    border-radius: 12px;
    text-align: center;
    margin-bottom: 18px;
    box-shadow: 4px 5px 5px 5px #e3e3e3;
}

.Invoice-Container .tableHead{
    font-size: 14px;
    font-family: Poppins-SemiBold;
    color: #9B9B9B;
}
.Invoice-Container .tableData{
    color: #010101;
    font-size: 13px;
    font-family: Poppins-Regular;
}
.Invoice-Container .table td, .table th {
    padding: 14px 26px !important;
    text-align: left;
    text-align: initial;
}

/* == Chat == */
.chat-Container {
    padding: 20px 10px;
}
.chat-Container .serviceCard {
    background-color: #fff;
    width: 100%;
    height: 550px;
    padding: 44px 26px;
    border-radius: 12px;
    text-align: center;
    margin-bottom: 18px;
    box-shadow: 4px 5px 5px 5px #e3e3e3;
}

.chat-Container .input-Card{
    background-color: #fff;
    width: 100%;
    height: auto;
    padding: 44px 26px;
    border-radius: 12px;
    text-align: center;
    margin-bottom: 18px;
    box-shadow: 4px 5px 5px 5px #e3e3e3;
}

.chat-Container .chat-header{
    display: flex;
    padding: 0px 50px;
}

.chat-Container .profile{
    height: 60px;
    width: 60px;
    background-color: teal;
    border-radius: 50%;
}
.chat-Container .user-name{
    color: #3A3A3A;
    font-family: Poppins-SemiBold;
    font-size: 16px;
    margin: 0;
    text-align: left;
    text-align: initial;
    margin-top: 10px;
}
.chat-Container .status{
    font-size: 10px;
    font-family: Poppins-Medium;
    color: #3a3a3a66;
    text-align: left;
    text-align: initial;
}
.chat-Container .input-Card input {
    /* padding: 10px 40px; */
    padding: 10px 90px 10px 28px;
    width: 100%;
    border-radius: 50px;
    border: 1px solid #e1e1e1;
}
.chat-Container .All-message-container {
    width: 100%;
    height: 370px;
    padding: 28px 20px;
    overflow-y: scroll;
}

.chat-Container .chat-img{
    width: 30px;
    height: 30px;
    background-color: antiquewhite;
    border-radius: 50%;
}

.chat-Container .incoming{
    display: flex;
    margin: 12px;
}
.chat-Container .chat-picture-incoming{
    height: 48px;
    width: 48px;
    background-color: #363535;
    overflow: hidden;
    border-radius: 50%;
}
.chat-Container .chat-message-incoming{
    width: 40%;
    background-color: #FF9517;
    /* margin-top: -10px; */
    overflow: hidden;
    border-radius: 8px;
    margin-left: 14px;
    padding: 12px 16px;
}


.chat-Container .outgoing{
    display: flex;
    flex-direction: row-reverse;
    margin: 12px;
}
.chat-Container .chat-picture-outgoing{
    height: 48px;
    width: 48px;
    background-color: #363535;
    overflow: hidden;
    border-radius: 50%;
}
.chat-Container .chat-message-outgoing {
    width: 40%;
    background-color: #C9C9C9;
    overflow: hidden;
    border-radius: 8px;
    padding: 12px 16px;
    margin-right: 14px;
    /* margin: -10px 14px 16px; */
}
.send-container{
    position: absolute;
    right: 58px;
    bottom: 88px;
}
.send-btn{
    background: #FF9517 !important;
    color: #fff;
    font-size: 12px;
    border-radius: 50px;
    box-shadow: 0 10px 30px 0 rgb(0 0 0 / 15%);
    width: 200px;
    padding: 8px 30px !important;
}

.chat-Container  :-ms-input-placeholder{
    font-size: 12PX;
}

.chat-Container  ::placeholder{
    font-size: 12PX;
}

.test{
    height: 100px;
    width: 100px;
    background-color: teal;
}
.test2{
    display: none;
}

.contractor-form-title {
    font-family: Poppins-SemiBold;
    color: #000000;
    font-size: 20px;
}

/* Profile  */
.profile-Container {
    padding: 20px 10px;
}
.profile-Container  .serviceCard {
    background-color: #fff;
    width: 100%;
    height: auto;
    padding: 44px 26px;
    border-radius: 12px;
    text-align: center;
    margin-bottom: 18px;
    box-shadow: 4px 5px 5px 5px #e3e3e3;
}

.profilePicture-container {
    height: 100px;
    width: 100px;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 10px;
}

.profile-Container .user-title{
    font-size: 16px;
    color: #000000cf;
    font-family: Poppins-SemiBold;
    text-align: left;
    text-align: initial;
}

.profile-Container .user-details{
    font-size: 16px;
    color: #000000cf;
    font-family: Poppins-SemiBold;
    text-align: left;
    text-align: initial;
}

.profile-Container  .edit-profile{
    font-size: 12px;
    font-family: Poppins-Regular;
    cursor: pointer;
}
.profile-Container  .edit-profile:hover{
    text-decoration: underline;
}
.profile-Container input{
    border-radius: 30px;
padding: 10px 30px;
}

.profile-Container .edit-container{
    background-color: #f7f7f7;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    position: absolute;
    margin-left: 97px;
    margin-top: 75px;
    box-shadow: 2px 2px 2px #afaeae40;
}
.profile-Container .edit-container input{
    position: absolute;
    margin-left: -18px;
    width: 24px;
    opacity: 0;
}

.edit-pen-icon{
    font-size: 14px !important;
    color: #FF9517 ;
}

/* notification  */
.notification-Container {
    padding: 20px 10px;
}
.notification-Container  .serviceCard {
    background-color: #fff;
    width: 100%;
    height: auto;
    padding: 44px 26px;
    border-radius: 12px;
    text-align: center;
    margin-bottom: 18px;
    box-shadow: 4px 5px 5px 5px #e3e3e3;
}

.notification-Container  .notificationCard {
    background-color: #0282a587;;
    width: 100%;
    height: 80px;
    padding: 18px 26px;
    border-radius: 12px;
    text-align: center;
    margin-bottom: 18px;
    box-shadow: 4px 5px 5px 5px #e3e3e3;
}

.notification-Container .notification-profile{
    height: 46px;
    width: 46px;
    border-radius: 8px;
    overflow: hidden;
}
.notification-Container  .notification-title{
    font-size: 14px;
    margin: 0px;
    font-family: 'Poppins-SemiBold';
    color: white;
}
.notification-Container  .date{
    font-size: 12px;
    font-family: Poppins-Regular;
    color: white;
}


@media (min-width: 768px) and (max-width: 1024px) {
    .Invoice-Container .table td, .table th {
        padding: 14px 20px !important;
    }
    .Invoice-Container .tableHead {
        font-size: 12px;
    }
}
@media (max-width: 768px) {
    .Invoice-Container .table td, .table th {
        padding: 14px 8px !important;
    }
    .chat-Container .serviceCard {
        margin-top: 45px;
        padding: 30px 22px;
    }
    .chat-Container .profile {
        height: 50px;
        width: 50px;
    }
    .chat-Container .All-message-container {
        padding: 16px 16px;
    }
}
@media (max-width: 425px) {
    .Invoice-Container .serviceCard {
        padding: 44px 12px;
        overflow: scroll;
    }
    .chat-Container .serviceCard {
        margin-top: 45px;
        padding: 20px 12px;
    }
    .chat-Container .All-message-container {
        padding: 8px 2px;
    }
    .chat-Container .chat-picture-incoming {
        height: 28px;
        width: 28px;
    }
    .chat-Container .chat-message-incoming {
        width: 50%;
    }
    .chat-Container .chat-message-outgoing {
        width: 50%;
    }
    .chat-Container .chat-picture-outgoing {
        height: 28px;
        width: 28px;
    }
    .chat-Container .input-Card {
        padding: 32px 12px;
    }
    .profile-Container .user-title {
        text-align: center;
    }
    .profile-Container .user-details {
        text-align: center;
    }
    .notification-Container .notificationCard {
        background-color: #0282a587;
        width: 100%;
        height: auto;
        padding: 18px 26px;
        border-radius: 12px;
        text-align: center;
        margin-bottom: 18px;
        box-shadow: 4px 5px 5px 5px #e3e3e3;
    }
    .send-container{
        right: 44px;
        bottom: 76px;
    }
}

@media (max-width: 375px) { 
    .contractor-form-title {
        font-size: 18px;
    }
    .notification-Container .notification-title {
        text-align: left;
        text-align: initial;
    }
    .notification-Container .date {
        text-align: left;
        text-align: initial;
    }
    .chat-Container .user-name {
        font-size: 14px;
    }
}

@media (max-width: 375px) { 
    .chat-Container .chat-header {
        display: flex;
        padding: 0px 20px;
    }
}
/* == customers == */
.customers-Container {
    padding: 20px 10px;
}
.customers-Container .serviceCard {
    background-color: #fff;
    width: 100%;
    height: auto;
    padding: 44px 26px;
    border-radius: 12px;
    text-align: center;
    margin-bottom: 18px;
    box-shadow: 4px 5px 5px 5px #e3e3e3;
}

.customers-Container .table td, .table th {
    padding: 14px 14px !important;
    text-align: left;
    text-align: initial;
    font-size: 13px;
}

/* Driver Details  */
.driver-Container {
    padding: 20px 10px;
}
.driver-Container .serviceCard {
    background-color: #fff;
    width: 100%;
    height: auto;
    padding: 44px 26px;
    border-radius: 12px;
    text-align: center;
    margin-bottom: 18px;
    box-shadow: 4px 5px 5px 5px #e3e3e3;
}
.driver-Container .driver-details{
    font-size: 16px;
    font-family: Poppins-SemiBold;
    color: #5c5c5c;
}

/* Add Admin  */
.admin-Container{
    padding: 20px 10px;
}
.admin-Container .serviceCard {
    background-color: #fff;
    width: 100%;
    height: auto;
    padding: 44px 26px;
    border-radius: 12px;
    text-align: center;
    margin-bottom: 18px;
    box-shadow: 4px 5px 5px 5px #e3e3e3;
}

.admin-Container input{
    border-radius: 30px;
    padding: 0px 22px;
}

/* Services */
.add-services:hover{
    text-decoration: underline;
    color: #FF9517;
}
.service-Modal{
    margin-top: 14%;
}

.service-Modal textarea {
    height: 120px;
    border-radius: 8px;
    padding: 10px 25px;
}
.service-Modal input {
    border-radius: 8px;
    padding: 10px 25px;
}
.service-Modal .modal-title{
    font-size: 24px;
    font-family: Poppins-SemiBold;
    color: #212529d1;
}

.service-Modal .close{
    position: absolute;
    right: 1px;
    top: 0px;
    font-size: 18px;
    color: #b31000;
}


/* Modal  */
.modal-content {
    padding: 18px 40px;
    text-align: center;
}

.pagination-container ul{
    display: flex !important;
    justify-content: space-evenly !important;
    align-items: center !important;
}
.pagination-container ul li{
    list-style: none;
}

.loader-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.RemoveBtn:hover{
    color: #be103b;
    box-shadow: 0px 2px 12px #e2c4c4;
}

.text-newWrap{
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
